<style lang="scss">
  .manage-group-btn {
    float:right;
    margin-top: -10px;
  }
</style>
<!--eslint-disable-->
<template>
  <div class="users-page">
    <section v-if="!loading">
      <div v-if="group" class="row">
        <div class="col-12">

          <b-card-actions :noActions=true :title="group.name + ' (v' + group.version + ')'" class="">

              Updating this group will also update any device enrolled in the group.

              <b-dropdown id="dropdown-grouped" variant="primary" right text="Manage" class="manage-group-btn" >
                <b-dropdown-group id="dropdown-group-1" header="Group Actions">

                  <b-dropdown-item v-if="$can('Update', 'Group')" :to="{ name: 'group-edit', params: { uid: group.uid } }">
                    Update Group
                  </b-dropdown-item>

                  <b-dropdown-item v-if="$can('Update', 'Group')" @click="showGroupModal('modal-sync-group')">
                    Sync Group
                  </b-dropdown-item>

                  <b-dropdown-item variant="danger" v-if="$can('Delete', 'Group')" @click="showGroupModal('modal-delete-group')">
                    Delete Group
                  </b-dropdown-item>
                </b-dropdown-group>

              </b-dropdown>


          </b-card-actions>

          <div v-if="!group.is_secure && group.is_secure != null" class="alert alert-warning p-2">
            <i class="fas fa-exclamation-triangle" />
            WARNING The current group settings are not secure
            <i class="fas fa-exclamation-triangle" />
          </div>

          <b-tabs content-class="my-2">
            <b-tab title="Applications" active>
              <group-applications :group="group"></group-applications>
            </b-tab>
            <b-tab title="Networks">
              <group-networks :group="group"></group-networks>
            </b-tab>
            <b-tab title="Activities" v-if="$can('Read', 'Activity')">
              <model-activities model="group" :uid="group.uid"></model-activities>
            </b-tab>
            <b-tab title="Purple Play" v-if="$can('Read', 'PurplePlay') && group.youtube_enabled">
              <group-channels model="group" :group="group"></group-channels>
            </b-tab>
            <b-tab title="News Feeds" v-if="$can('Read', 'NewsFeeds') && group.news_feeds_enabled">
              <group-news-feeds model="group" :group="group"></group-news-feeds>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div v-else>No Groups found</div>

      <b-modal id="modal-update-group" title="Update group" hide-footer>
        <update-group v-if="group" :group-existing="group" @refresh="refreshGroup()" @close="closeModals()"></update-group>
      </b-modal>

      <b-modal  id="modal-delete-group" title="Delete group" modal-class="modal-danger" hide-footer>
        <delete-modal
          v-if="group"
          @close="closeModals()" @delete="deleteGroup(group)"
          :subtitle="group.name"
          title="Are you sure you would like to delete this group?"></delete-modal>
      </b-modal>


      <b-modal id="modal-sync-group" title="" hide-footer>
        <warning-modal v-if="group" title="Are you sure you wish to sync the policies for this group?"
             ok-variant="success"
             @ok="syncGroup(group)"
             @close="closeModals()"
        />
      </b-modal>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading group"></b-spinner>
    </section>

  </div>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import GroupApplications from '@/views/groups/viewGroup/sections/GroupApplications.vue';
  import GroupService from '../../../services/GroupService';
  import DeleteModal from '../../../components/modals/DeleteModal.vue';
  import WarningModal from '../../../components/modals/WarningModal.vue';
  import GroupNetworks from './sections/GroupNetworks.vue';
  import GroupChannels from './sections/GroupChannels.vue';
  import GroupNewsFeeds from './sections/GroupNewsFeeds';
  import ModelActivities from '../../activities/ModelActivities.vue';

  export default {
    name: 'GroupView',
    components: {
      GroupApplications,
      DeleteModal,
      WarningModal,
      GroupNetworks,
      GroupChannels,
      GroupNewsFeeds,
      ModelActivities,
      BCardActions,
    },
    props: {
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        loading: true,
        group: null,
      };
    },
    mounted() {
      this.getGroup();
    },
    methods: {
      refreshGroup() {
        this.closeModals();
        this.getGroup();
      },
      closeModals() {
        this.$bvModal.hide('modal-add-group');
        this.$bvModal.hide('modal-update-group');
        this.$bvModal.hide('modal-delete-group');
        this.$bvModal.hide('modal-sync-group');
      },
      getGroup() {
        this.loading = true;
        GroupService.getGroup(this.uid).then(res => {
          this.group = res.data;
          this.sortApplications();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get group, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }).finally(() => {
          this.loading = false;
        });
      },
      showGroupModal(modalName) {
        this.$bvModal.show(modalName);
      },
      sortApplications() {
        this.group.applications.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      },
      syncGroup(group) {
        GroupService.syncGroup(group.uid).then(() => {
          this.$toast.success(`Synchronised group ${group.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.closeModals();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not sync group, please refresh and try again'
          if (res && res.data.error) {
            errorText = res.data.error
          }
          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      deleteGroup(group) {
        GroupService.deleteGroup(group.uid).then(() => {
          this.$toast.success(`Deleted group ${group.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$router.push({ name: 'groups' });
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete group, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
    },
  };
</script>
